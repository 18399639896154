import {
  capitalizeFirstLetter,
  Card,
  CopyToClipboard,
  fCurrency,
  fNullData,
  fNumberWithCommas,
  fPercent,
  fReadableCurrency,
  generateImageUrl,
  Label,
} from '@autone/ui';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import {
  Box,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const ProductCard = ({
  product,
  setOpen,
  productDataCallback,
  selectedCurrency,
}) => {
  const theme = useTheme();

  // open copied tooltip
  const [openTooltip, setOpenTooltip] = useState(false);

  const handleTooltipOpen = () => {
    setOpenTooltip(true);
    setTimeout(() => setOpenTooltip(false), 1000);
  };

  const handleProductCardClick = () => {
    productDataCallback(product);
    setOpen(true);
  };

  const getReturnRate = (returnRate) => {
    if (returnRate === 'nm') {
      return { value: 'nm', color: 'default' };
    }
    if (returnRate > 0.2) {
      return { value: fPercent(returnRate), color: 'error' };
    }
    return { value: fPercent(returnRate), color: 'success' };
  };

  const getSellThrough = (sellThrough) => {
    if (sellThrough === 'nm') {
      return { value: 'nm', color: 'default' };
    }
    if (sellThrough < 0.4) {
      return { value: fPercent(sellThrough), color: 'error' };
    }
    if (sellThrough > 0.7) {
      return { value: fPercent(sellThrough), color: 'warning' };
    }

    return { value: fPercent(sellThrough), color: 'success' };
  };

  const {
    'Product.product_id': productId,
    'Product.styleDescription': productStyleDesc,
    'Product.colorDescription': productColorDesc,
    'Image.url': imageUrl,
    'ProductPrice.rrp': priceRrp,
    'Sales.totalSalesValue': totalSalesValue,
    'Sales.totalSalesQuantity': totalSalesQuantity,
    'Product.brandDescription': brandDesc,
    totalInventoryQuantity,
    totalInventoryValue,
    returnRate,
    sellThrough,
  } = product || {};

  const { value: returnRateValue, color: returnRateColor } =
    getReturnRate(returnRate);

  const { value: sellThroughValue, color: sellThroughColor } =
    getSellThrough(sellThrough);

  return (
    <Card
      image={
        <LazyLoadImage
          height="225px"
          src={generateImageUrl({ imageUrl })}
          width="100%"
          style={{
            objectFit: !(imageUrl && imageUrl.includes('default'))
              ? 'contain'
              : undefined,
          }}
        />
      }
      label={
        <>
          {brandDesc && (
            // filled variant as ghost is hard to see when overlapping a black background
            <Label variant="filled" color="default">
              {capitalizeFirstLetter(brandDesc)}
            </Label>
          )}
        </>
      }
    >
      <Box sx={{ position: 'absolute', top: 5, right: 5 }}>
        <IconButton onClick={handleProductCardClick}>
          <OpenInFullIcon
            fontSize="small"
            sx={{
              color: theme.palette.grey[600],
            }}
          />
        </IconButton>
      </Box>
      <Box
        sx={{
          p: 2,
        }}
      >
        <Typography
          variant="smallcaption"
          display="block"
          sx={{ fontWeight: 'fontWeightBold' }}
        >
          <Box component="span">
            <Typography>
              {productStyleDesc && capitalizeFirstLetter(productStyleDesc)}
            </Typography>
            <Typography>
              {productColorDesc && capitalizeFirstLetter(productColorDesc)}
            </Typography>
          </Box>
          <Box component="span" sx={{ float: 'right' }}>
            {priceRrp && fCurrency(fNullData(priceRrp), selectedCurrency)}
          </Box>
        </Typography>
        <Stack alignItems="center" direction="row">
          <Typography
            variant="smallcaption"
            noWrap
            display="block"
            sx={{ color: theme.palette.grey[600] }}
          >
            {productId || 'Not defined'}
          </Typography>
          <CopyToClipboard value={productId}>
            <IconButton onClick={handleTooltipOpen} sx={{ ml: 0.5 }}>
              <Tooltip
                sx={{
                  backgroundColor: theme.palette.primary.main,
                }}
                PopperProps={{
                  disablePortal: true,
                }}
                open={openTooltip}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title="Copied!"
              >
                <ContentCopyIcon
                  sx={{
                    fontSize: '14px',
                    color: theme.palette.primary.main,
                  }}
                />
              </Tooltip>
            </IconButton>
          </CopyToClipboard>
        </Stack>
        <Box
          sx={{
            mt: 1,
            display: 'block',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ float: 'left', mb: 3 }}>
            <Stack direction="row" spacing={1} mb={0.5}>
              <Typography variant="smallcaption" display="block">
                Sales
              </Typography>
              <Label>
                {fReadableCurrency(totalSalesValue * 1, selectedCurrency)}
              </Label>
              <Label>{`${fNumberWithCommas(
                totalSalesQuantity * 1,
              )} units`}</Label>
            </Stack>
            <Stack direction="row" spacing={1} mb={0.5}>
              <>
                <Typography variant="smallcaption" display="block">
                  Current inventory
                </Typography>
                {totalInventoryValue === 'nm' ? (
                  <Label>nm</Label>
                ) : (
                  <Label>
                    {fReadableCurrency(
                      totalInventoryValue * 1,
                      selectedCurrency,
                    )}
                  </Label>
                )}
                <Label>
                  {`${fNullData(
                    fNumberWithCommas(totalInventoryQuantity),
                  )} units`}
                </Label>
              </>
            </Stack>
            <Stack direction="row" spacing={1} mb={0.5}>
              <Typography variant="smallcaption" display="block">
                Return rate
              </Typography>
              <Label variant="filled" color={returnRateColor}>
                {returnRateValue}
              </Label>
            </Stack>
            <Stack direction="row" spacing={1} mb={0.5}>
              <Typography variant="smallcaption" display="block">
                ST
              </Typography>
              <Label variant="filled" color={sellThroughColor}>
                {sellThroughValue}
              </Label>
            </Stack>
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

export default ProductCard;

ProductCard.propTypes = {
  product: PropTypes.object,
  setOpen: PropTypes.func,
  productDataCallback: PropTypes.func,
  selectedCurrency: PropTypes.string,
};
